import { Type } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBarConfig } from '@angular/material/snack-bar';

// Use::Dialog/Modal:Start
export function getMatDialogConfig<C>(dialog: MatDialog, component: Type<C>, data = {}): MatDialogConfig<C> {
  const config = new MatDialogConfig();

  config.disableClose = true;
  config.autoFocus = true;
  // we use mixins for styling this
  config.panelClass = 'modal-panel';
  config.backdropClass = 'backdrop-modal-panel';
  config.closeOnNavigation = true;
  // to read data from dialog component use this in contructor
  // @Inject(MAT_DIALOG_DATA): private dialogData: any
  config.data = data;

  return config;
}
// Use::Dialog/Modal:End

export function getSnackBarConfig<C>(seconds: number = 5000): MatSnackBarConfig<C> {
  const config = new MatSnackBarConfig();
  config.horizontalPosition = 'center';
  config.verticalPosition = 'bottom';
  config.duration = seconds;
  return config;
}
